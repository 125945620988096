const popup = document.querySelector('.popup')
const popupBody = document.querySelector('.popup__body')

interface IPopupOptions {
	/**
	 * Close popup while click outside him
	 */
	closeOnBodyClick: boolean,
}

function initPopup(props: IPopupOptions): void {
	if (!popup) return

	const popupBtns = document.querySelectorAll<HTMLButtonElement>('*[data-btn-popup]')
	popupBtns.forEach(btn => btn.addEventListener('click', popupBtnHandler))

	if (!props.closeOnBodyClick || !popupBody) return
	popupBody.addEventListener('click', function (e) {
		if (e.target !== e.currentTarget) return
		closeAllPopups()
	})

	const closePopupBtns = document.querySelectorAll('.popup__content .close-btn')
	closePopupBtns.forEach(i => i.addEventListener('click', closeAllPopups))
}

function popupBtnHandler(e: MouseEvent): void {
	const target = e.currentTarget as HTMLButtonElement

	if (!target) return

	const targetPopupStr = target.dataset.btnPopup
	const targetPopupEl = document.querySelector(`.popup__content[data-popup="${ targetPopupStr }"]`)

	if (!targetPopupEl) return
	openPopup(targetPopupEl)
}

function openPopup(targetPopup: Element): void {
	closeActivePopup()

	if (!popup) return

	popup.classList.add('opened')
	document.documentElement.classList.add('disable-scroll')
	targetPopup.classList.add('opened')
}

function closeAllPopups(): void {
	if (!popup) return

	popup.classList.remove('opened')
	document.documentElement.classList.remove('disable-scroll')

	closeActivePopup()
}

function closeActivePopup(): void {
	const activePopups = document.querySelectorAll('.popup__content.opened')
	activePopups.forEach(i => i.classList.remove('opened'))
}

export default initPopup
export { openPopup, closeAllPopups, closeActivePopup }