const callForms = document.querySelectorAll<HTMLFormElement>('.call-form')

callForms.forEach(form => {
	form.addEventListener('submit', function (e) {
		e.preventDefault()
		const form = e.target as HTMLFormElement
		const formData = new FormData(form)

		const details: IFormEventDetails = {
			form: form,
			formData: formData,
		}

		const formValidatedEvent = new CustomEvent('formValidated', { detail: details })

		const timeInput = form.querySelector<HTMLInputElement>('input[name="time"]')
		let valid = true, errorString = ''

		if (!formData.get('name')) {
			valid = false
			errorString += 'Заполните Ваше имя\n'
		}
		if (!formData.get('tel')) {
			valid = false
			errorString += 'Заполните Ваш телефон\n'
		}
		if (timeInput && !formData.get('time')) {
			valid = false
			errorString += 'Выберите удобное время для звонка\n'
		}

		if (!valid) {
			form.classList.add('not-valid')

			const errorContainer = form.querySelector<HTMLElement>('.form__error-text')
			errorContainer!.innerText = errorString

			return
		}

		form.classList.remove('not-valid')
		form.dispatchEvent(formValidatedEvent)
	})
})