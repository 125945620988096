import { openPopup } from './popupUtils'

const tasksItems = document.querySelectorAll<HTMLElement>('.task')
tasksItems.forEach(i => {
	i.addEventListener('click', function () {
		const targetId = i.dataset.taskId
		const targetPopup = document.querySelector(`.popup__content[data-task-id="${ targetId }"]`)

		if (!targetPopup) return

		openPopup(targetPopup)
	})
})