import createMqSwipers, { IMqSwiperOptions } from './createMqSwipers'

const mqSwipers: IMqSwiperOptions[] = [
	{
		mq: '(max-width: 900px)',
		obj: {
			selector: '.team .swiper',
			options: {
				spaceBetween: 0,
				slidesPerView: 1,
			},
		},
	},
	{
		mq: '(max-width: 880px)',
		obj: {
			selector: '.clients .swiper',
			options: {
				spaceBetween: 0,
				slidesPerView: 'auto',
				autoplay: true,
			},
		},
	},
]

createMqSwipers(mqSwipers)