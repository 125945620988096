import { drawPath, getPathString, createSVG } from './svg'

const triangleDrawers = document.querySelectorAll<HTMLElement>('.triangle-drawer')
// Grid step
const STEP = 36
const COLORS = [
	'#be1e2d',
	'#ed1c24',
	'#f15a29',
	'#f7941e',
	'#fbb040',
	'#fff200',
	'#d7df23',
	'#8dc63f',
	'#00a651',
	'#006838',
	'#00a79d',
	'#27aae1',
	'#1c75bc',
	'#2b3990',
	'#262262',
	'#662d91',
	'#92278f',
	'#9e1f63',
	'#da1c5c',
	'#ee2a7b',
]
let currentColorIndex = -1

interface IDrawnTriangles {
	[i: string]: SVGPathElement
}

const drawnTriangles: IDrawnTriangles = {}

triangleDrawers.forEach(i => createTriangleDrawer(i))

function createTriangleDrawer(container: HTMLElement) {
	const svg = createSVG(container)
	if (!svg) return

	container.addEventListener('mousemove', e => {
		const point: IPoint = { x: e.offsetX, y: e.offsetY }

		mouseHandler(point, svg)
	})
}

interface ISquare {
	path: TGeometry,
	center: IPoint
}

let previousPath: string | null = null
let prevPoint: IPoint | null = null

let processing: boolean = false

function mouseHandler(point: IPoint, svg: SVGElement, autocompleteMode?: boolean) {
	if (!autocompleteMode && processing) return
	if (!autocompleteMode) processing = true

	const square = getSquare(point)

	const p = {
		x: point.x - square.path[0].x,
		y: point.y - square.path[0].y,
	}

	const triangleStr: TTrianglePos = p.x < STEP / 2
	  ? p.y < STEP / 2
		? p.x > p.y
		  ? 'top'
		  : 'left'
		: p.x + p.y > STEP
		  ? 'bottom'
		  : 'left'
	  : p.y < STEP / 2
		? p.x + p.y > STEP
		  ? 'right'
		  : 'top'
		: p.x > p.y
		  ? 'right'
		  : 'bottom'

	const triangle = getTriangle(square, triangleStr)

	if (!autocompleteMode && prevPoint) {
		// Distance between previous point and current
		const distance = {
			x: Math.abs(prevPoint.x - point.x),
			y: Math.abs(prevPoint.y - point.y),
		}

		// Since mousemove event update rate is low, need to fill empty between two point
		if (distance.x > 15 || distance.y > 15) autocompleteLine(point, svg)
	}

	drawTriangle(triangle, svg)

	if (!autocompleteMode) {
		processing = false
		prevPoint = point
	}
}

function autocompleteLine(point: IPoint, svg: SVGElement): void {
	if (!prevPoint) return

	const distance = {
		x: Math.abs(prevPoint.x - point.x),
		y: Math.abs(prevPoint.y - point.y),
	}

	const direction = {
		x: prevPoint.x < point.x ? 1 : -1,
		y: prevPoint.y < point.y ? 1 : -1,
	}

	const grDist: 'x' | 'y' = distance.x > distance.y ? 'x' : 'y'

	const loopCondition = (value: number): boolean => direction[grDist] === 1
	  ? value <= point[grDist]
	  : value >= point[grDist]

	const calculateVector = (iteration: number, vector: 'y' | 'x'): number => {
		return prevPoint![vector] + Math.floor(distance[vector] * iteration / distance[grDist]) * direction[vector]
	}

	for (let i = 0, value = prevPoint[grDist]; loopCondition(value); i++, value += direction[grDist]) {
		mouseHandler({
			x: grDist === 'x' ? value : calculateVector(i, 'x'),
			y: grDist === 'y' ? value : calculateVector(i, 'y'),
		}, svg, true)
	}
}

function drawTriangle(triangle: TGeometry, svg: SVGElement): void {
	const pathD = getPathString(triangle)

	if (previousPath === pathD) return

	if (!drawnTriangles[pathD]) {
		drawnTriangles[pathD] = drawPath({
			d: pathD,
			fill: getColor(),
			stroke: 'rgba(255,255,255,0.3)',
			strokeWidth: '1px',
		}, svg)
	}

	// drawnTriangles[pathD].style.fill = '#ffffff'
	// drawnTriangles[pathD].style.fill = getColor()

	previousPath = pathD
	drawnTriangles[pathD] = drawnTriangles[pathD]
}

function getSquare(point: IPoint): ISquare {
	const startPos: IPoint = {
		x: Math.floor(point.x / STEP) * STEP,
		y: Math.floor(point.y / STEP) * STEP,
	}

	const path: TGeometry = [
		startPos,
		{ x: startPos.x + STEP, y: startPos.y },
		{ x: startPos.x + STEP, y: startPos.y + STEP },
		{ x: startPos.x, y: startPos.y + STEP },
	]

	const center: IPoint = { x: startPos.x + STEP / 2, y: startPos.y + STEP / 2 }

	return { path, center }
}

type TTrianglePos = 'top' | 'right' | 'bottom' | 'left'

/**
 * Returns a triangle from {pos} using the points of the {squareObj} arranged clockwise,
 * started from top-left
 */
function getTriangle(squareObj: ISquare, pos: TTrianglePos): TGeometry {
	const triangles = {
		top: () => [
			{ x: squareObj.path[0].x, y: squareObj.path[0].y },
			{ x: squareObj.path[1].x, y: squareObj.path[1].y },
			{ x: squareObj.center.x, y: squareObj.center.y },
		],
		right: () => [
			{ x: squareObj.path[1].x, y: squareObj.path[1].y },
			{ x: squareObj.path[2].x, y: squareObj.path[2].y },
			{ x: squareObj.center.x, y: squareObj.center.y },
		],
		bottom: () => [
			{ x: squareObj.path[2].x, y: squareObj.path[2].y },
			{ x: squareObj.path[3].x, y: squareObj.path[3].y },
			{ x: squareObj.center.x, y: squareObj.center.y },
		],
		left: () => [
			{ x: squareObj.path[3].x, y: squareObj.path[3].y },
			{ x: squareObj.path[0].x, y: squareObj.path[0].y },
			{ x: squareObj.center.x, y: squareObj.center.y },
		],
	}

	return triangles[pos]()
}

function getColor() {
	currentColorIndex++
	if (currentColorIndex === COLORS.length) currentColorIndex = 0
	return COLORS[currentColorIndex]
}
