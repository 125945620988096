/**
 *	Create and return svg tag. Move svg to container if it has.
 */
function createSVG(container?: HTMLElement, props?: ISVGOptions): SVGElement {
	const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')

	svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
	if (props) setAttributesToElement(props, svg)

	if (container) container.appendChild(svg)

	return svg
}

function drawPath(props: IPathProps, svg?: SVGElement): SVGPathElement {
	const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')

	path.setAttribute('d', props.d)

	setAttributesToElement(props, path)

	if (svg) svg.appendChild(path)

	return path
}

function setAttributesToElement(attributes: object, el: SVGElement) {
	for (let propName in attributes) {
		if (propName === 'd') continue

		el.setAttribute(propName, (attributes as any)[propName])
	}
}

interface IPathStringOptions {
	/**
	 * Create line at the end to first point. Default - 'true'
	 */
	autocomplete: boolean
}

function getPathString(geometry: TGeometry, options?: IPathStringOptions): string {
	options = {
		autocomplete: true,
	}

	let result = `M${ geometry[0].x },${ geometry[0].y }`
	geometry.forEach(point => {
		result += ` L${ point.x },${ point.y }`
	})

	if (options.autocomplete)
		result += ` L${ geometry[0].x },${ geometry[0].y }`

	return result
}

export { createSVG, drawPath, getPathString }