const accordions = document.querySelectorAll<HTMLElement>('.accordion')

accordions.forEach(initAccordion)

function initAccordion(el: HTMLElement) {
	const titleWrapper = el.querySelector<HTMLElement>('.accordion__title-wrapper')
	const body = el.querySelector<HTMLElement>('.accordion__body')

	if (!titleWrapper || !body) return
	const initialBodyHeight = body.offsetHeight
	el.classList.add('initialized')

	const details: IAccordionEventDetails = {
		titleWrapper: titleWrapper,
		body: body,
		initialBodyHeight: initialBodyHeight,
	}

	const accordionOpenedEvent = new CustomEvent('accordionOpened', { detail: details })
	const accordionClosedEvent = new CustomEvent('accordionClosed', { detail: details })

	titleWrapper.addEventListener('click', function () {
		el.classList.toggle('opened')

		if (el.classList.contains('opened'))
			el.dispatchEvent(accordionOpenedEvent)
		else
			el.dispatchEvent(accordionClosedEvent)
	})

	el.addEventListener('accordionOpened', (e: CustomEvent<IAccordionEventDetails>) => {
		e.detail.body.style.maxHeight = `${ e.detail.initialBodyHeight }px`
	})

	el.addEventListener('accordionClosed', (e: CustomEvent<IAccordionEventDetails>) => {
		e.detail.body.style.maxHeight = String(0)
	})
}

